/* You can add global styles to this file, and also import other style files */

header .header-nav .nav-container.breakpoint-on .nav-menu .menu-items ul li.has-submemu>a::after {
	content: "\f107";
	font-family: "Font Awesome 5 Pro";
	font-weight: 300;
	display: block;
	position: absolute;
	right: 0;
	height: 45px;
	width: 45px;
	top: 0;
	border-left: 1px solid rgba(255, 255, 255, 0.5);
	z-index: 2;
	background: transparent;
	text-align: center;
	line-height: 45px;
	cursor: pointer;
	color: #fff;
	font-size: 20px;
}
.skill-section .piechart-boxes .chart-box .chart {
	max-width: 100%;
}
.d-contents {
	color: #fff;
	display: contents;
}
.testimonial-section-three .testimonial-items .testimonial-item .author-thumb::before {
	background-image: url(assets/img/icons/down-arrow.png);
}
.testimonial-section-three.no-bg .testimonial-items .testimonial-item .author-thumb::before {
	background-image: url(assets/img/icons/down-arrow-2.png);
}
.faq-section .faq-loop.accordion .card .card-header .btn-link {
	font-size: 18px;
	padding: 20px 40px 20px 30px;
	cursor: pointer;
	transition: 0.4s;
	position: relative;
	color: #14212b;
	font-family: "Source Sans Pro", sans-serif;
	font-weight: 700;
	display: flex;
	width: 100%;
	border: none;
	border-radius: 0;
	text-align: left;
}
.faq-section .faq-loop.accordion .card .card-header .btn-link span.icons {
	position: absolute;
	right: 30px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%);
}
.faq-section .faq-loop.accordion .card .card-header .btn-link .icons i:before {
	content: "\f068";
}
.faq-section .faq-loop.accordion .card .card-header .btn-link.collapsed .icons i:before {
	content: "\f067";
}
.faq-section .faq-loop.accordion .card .card-header .btn-link.collapsed {
	color: #14212b;
	background-color: #fff;
}
.faq-section .faq-loop.accordion .card .card-header .btn-link,
.faq-section .faq-loop.accordion .card .card-header:hover .btn-link {
	color: #fff;
	background-color: #ff4a17;
	text-decoration: none;
}
/* border style */

.faq-section .faq-loop.accordion.border-style .card .card-header {
	border: none;
}
.faq-section .faq-loop.accordion.border-style .card .card-header .btn-link.collapsed {
	color: #14212b;
	background-color: #fff;
	border: 2px solid #eaeaea;
}
.faq-section .faq-loop.accordion.border-style .card .card-header .btn-link,
.faq-section .faq-loop.accordion.border-style .card .card-header:hover .btn-link {
	color: #14212b;
	background-color: #fff;
	text-decoration: none;
	border: 2px solid #ff4a17;
}
.faq-section .faq-loop.accordion.border-style .card .card-header:hover .btn-link.collapsed {
	border: 2px solid #ff4a17;
}
.faq-section .faq-loop.accordion.border-style .card .card-header:hover .btn-link,
.faq-section .faq-loop.accordion.border-style .card .card-header .btn-link {
	border-bottom: 2px solid transparent;
}
.member-details-wrapper .member-details .member-picture-wrap .member-picture img,
.member-details-wrapper .member-details .member-picture-wrap .member-picture {
	height: 100%;
	object-fit: cover;
}
/* Pagination */

pagination-template .ngx-pagination {
	margin-top: 20px;
	justify-content: center;
	margin-bottom: 0;
	display: flex;
	padding-left: 0;
	list-style: none;
	border-radius: .25rem;
}
pagination-template .ngx-pagination li {
	margin: 0;
	position: relative;
}
pagination-template .ngx-pagination li.pagination-next,
pagination-template .ngx-pagination li.pagination-previous {
	padding: 0;
	display: block;
}
pagination-template .ngx-pagination li.pagination-next span,
pagination-template .ngx-pagination li.pagination-previous span,
pagination-template .ngx-pagination li a {
	color: #616161;
	border: 2px solid #eaeaea;
	background-color: #fff;
	font-size: 16px;
	font-weight: 600;
	padding: 15px 21px!important;
	margin: 0;
	position: relative;
	margin-left: 0;
	margin-right: 5px;
	height: 60px;
	width: 60px;
	display: block;
	line-height: 2;
	text-align: center;
	border-radius: 50%;
}
pagination-template .ngx-pagination li.current {
	color: #fff;
	border: 2px solid #ff4a17;
	background-color: #ff4a17;
	font-size: 16px;
	font-weight: 600;
	padding: 15px 21px!important;
	margin: 0;
	position: relative;
	margin-left: 0;
	margin-right: 5px;
	height: 60px;
	width: 60px;
	display: block;
	line-height: 2;
	text-align: center;
	border-radius: 50%;
}
pagination-template .ngx-pagination li.pagination-next::after,
pagination-template .ngx-pagination li.pagination-next a:after,
pagination-template .ngx-pagination li.pagination-previous a::before,
pagination-template .ngx-pagination li.pagination-previous::before {
	display: none!important;
}
.mt-negative .cta-wrap {
    margin-top: -60px;
}
.sidebar .widget.recent-post-widget .single-post .post-thumb img {
	height: 75px;
	object-fit: cover;
	object-position: center;
}
.comment-template .comment-list li .author-img img {
	width: 100px;
	height: 100px;
	border-radius: 50%;
	object-fit: cover;
}
.project-boxes .project-box.wide-long-box {
	height: auto;
}
.project-boxes .wide-long-box .project-box {
	height: 770px;
}
button:focus,
ngb-rating:focus {
	outline: none!important;
	box-shadow: none!important;
}
.shop-details-wrap .product-details .product-details-tab .product-review .review-list li .review-rating li i,
.product-loop .single-product .rating li i{
	color: #14212b;
}
.product-loop .single-product .rating li i.active{
	color: #ffb503;
}
.shop-details-wrap .product-details .product-details-tab .product-review .review-list li .review-rating li i.active{
	color: #ff4a17;
}
.shop-details-wrap .product-details .product-details-tab .tab-filter-nav .nav a::before{
	bottom: -11px;
}
.shop-details-wrap .product-details .product-details-tab .product-review .review-list li .review-thumb img{
	width: 60px;
	height: 60px;
	object-fit: cover;
	object-position: center;
	border-radius: 50%;
}
.shop-details-wrap .product-details .product-gallery{
	display: flex;
	justify-content: space-between;
}
.shop-details-wrap .product-details .product-gallery .product-gallery-arrow{
	float: none;
	max-width: 100%;
	width: 150px;
	margin-right: 15px;
}
.shop-details-wrap .product-details .product-gallery .gallery-slider-warp{
	float: none;
}
.shop-details-wrap .product-details .product-gallery .product-gallery-arrow .slick-list{
	height: auto!important;
}
@media (max-width: 767px) {
	.project-boxes .project-box.wide-long-box {
		height: auto;
	}
	.project-boxes .wide-long-box .project-box {
		height: 630px;
	}
	.shop-details-wrap .product-details .product-gallery{
		flex-wrap: wrap;
		flex-direction: column-reverse;
	}
	.shop-details-wrap .product-details .product-gallery .gallery-slider-warp,
	.shop-details-wrap .product-details .product-gallery .product-gallery-arrow{
		width: 100%;
		margin-right: 0;
	}
	.shop-details-wrap .product-details .product-gallery .product-gallery-arrow{
		margin-top: 30px;
	}
	.shop-details-wrap .product-details .product-gallery .product-gallery-arrow .slick-thumbs{
		margin: 0 -15px;
	}
	.shop-details-wrap .product-details .product-gallery .product-gallery-arrow .slick-thumbs .slick-slide{
		padding: 0 15px;
	}
	.navbar-btn.nav-push-item{
		display: none;
	}
}
.project-boxes .project-box.wide-long-box .project-desc {
	left: auto;
	right: auto;
}
.project-boxes .wide-long-box .project-box .project-desc {
	left: 65px;
	right: 65px;
}
@media (max-width: 991px) {
	.project-boxes .project-box.wide-long-box .project-desc {
		left: auto;
		right: auto;
	}
	.project-boxes .wide-long-box .project-box .project-desc {
		left: 30px;
		right: 30px;
	}
}
@media (max-width: 575px){
	footer .widget.insta-feed-widget .insta-images {
		grid-template-columns: repeat(3, 1fr);
	}
}

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
